import * as TablerIcons from '@tabler/icons-react';

type IconProps = {
  name: keyof typeof TablerIcons;
  color?: string;
  size?: number;
  strokeWidth?: number;
};

type IconComponent = (
  props: TablerIcons.TablerIconsProps
) => JSX.Element;

export const Icon = ({ name, ...props }: IconProps) => {
  const IconComponent = TablerIcons[name] as IconComponent;
  if (!IconComponent) {
    throw new Error(`Invalid icon name: ${name}`);
  }
  return (
    <IconComponent {...props} />
  );
};