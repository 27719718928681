import {createStyles, Title, Text, Card, SimpleGrid, Container, rem, Box, Image} from '@mantine/core';
import { useState } from 'react';
import SemorySVG from '@/assets/home/memory.svg'
import PhonelinkSVG from '@/assets/home/phonelink.svg'
import HandsSVG from '@/assets/home/hands.svg'
import LabourSVG from '@/assets/home/labour.svg'


const mockdata = [
    {
        title: 'Safety and stability',
        description:
            'Real Server equipment resource, powerful IDC resources and energy supply',
        icon: SemorySVG,
        imageOnHover: '/home/2xz25fdz.png'
    },
    {
        title: 'Brand cooperation',
        description:
            'Cooperative server vendors are all top-ranked brands in the world',
        icon: HandsSVG,
        imageOnHover: '/home/2xz25fdz.png'
    },
    {
        title: 'Easy to operate',
        description:
            'Professional operation and maintenance personnel, user operation is convenient',
        icon: PhonelinkSVG,
        imageOnHover: '/home/2xz25fdz.png'
    },
    {
        title: 'Save mind and effort',
        description:
            '24-hour dedicated maintenance, no need to worry about disconnection',
        icon: LabourSVG,
        imageOnHover: '/home/2xz25fdz.png'
    },
];

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: rem(34),
        fontWeight: 900,

        [theme.fn.smallerThan('sm')]: {
            fontSize: rem(24),
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',
    },

    card: {
        border: `${rem(1)} solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
        }`,
        width: rem(358),
        height: rem(270)
    },

    icon: {
        margin: '0 auto',
        width: rem(86),
        height: rem(86),
    },

    cardTitle: {
        display: 'flex',
        justifyContent: 'center'
    },
    box: {
        background: '#FBBF24',
        width: rem(346),
        height: rem(11),
        bottom: rem(12)
    }
}));

export function FeaturesCards() {
    const [visibleState, setVisibleState] = useState<{ [key: number]: boolean }>({});

    const handleMouseEnter = (index: number) => {
        setVisibleState({ ...visibleState, [index]: true });
    };

    const handleMouseLeave = (index : number) => {
        setVisibleState({...visibleState, [index]: false});
    }

    const { classes, theme } = useStyles();
    const features = mockdata.map((feature, index) => {
        return (
            <Card
                key={feature.title}
                shadow="md"
                radius={visibleState[index] ? 0 : 'md'}
                className={`${classes.card} flex flex-col justify-center`}
                padding={visibleState[index] ? 0 : 'xl'}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
            >
                {visibleState[index] && (
                    <Image alt="" src={feature.imageOnHover} height={'100%'} width={'100%'} />
                )}
                {!visibleState[index] && (
                    <feature.icon
                        size={rem(50)}
                        stroke={2}
                        color="#FBBF24"
                        className={classes.icon}
                    />
                    // <SemorySVG/>
                )}
                {!visibleState[index] && (
                    <Text fz="lg" fw={500} className={classes.cardTitle} mt="md">
                        {feature.title}
                    </Text>
                )}
                {!visibleState[index] && (
                    <Text fz="sm" c="dimmed" mt="sm" ta="center">
                        {feature.description}
                    </Text>
                )}
            </Card>
        );
    });

    return (
        <Container size="1600" py="xl">
            <Box
                className="flex flex-col items-center relative"
                style={{ height: rem(52) }}
            >
                <Title order={1} style={{ zIndex: 1, fontSize: rem(36) }}>
                    Platform Advantages
                </Title>
                <Box className={`${classes.box} absolute`}></Box>
            </Box>

            <Text c="dimmed" className={classes.description} ta="center" mt="md">
                Founded in 2021, it focuses on the blockchain data center industry. It
                has invested and built several blockchain data centers in East Asia, the
                United States,
            </Text>

            <SimpleGrid
                cols={4}
                spacing="xl"
                mt={50}
                breakpoints={[{ maxWidth: 'md', cols: 1 }]}
                className="flex flex-col items-center md:flex-row md:-4"
            >
                {features}
            </SimpleGrid>
        </Container>
    );
}

