import { Inter } from 'next/font/google'
import { createStyles, BackgroundImage, Box, Button, Group, rem, Text, Title, Container } from '@mantine/core';
import { FeaturesCards } from '@/components/Home/AdvantageComponent'
import { Icon } from '@/components/Icon'
import AppStoreSVG from '@/assets/home/app-store-ios.svg'
import GooglePlaySVG from '@/assets/home/google-play.svg'
import AndroidSVG from '@/assets/home/android-fill.svg'

const inter = Inter({ subsets: ['latin'] })


const useStyles = createStyles((theme) => ({
  download: {
    overflow: 'hidden',
    width: '100%',
    backgroundColor: '#0A080D',
    display: 'flex'
  },
  burger: {
    [theme.fn.largerThan('sm')]: {
      display: 'none',
    },
  },
}));

export default function Home() {
  const { classes, cx } = useStyles();
  console.log(classes.download)
  return (
    <main className="flex min-h-screen flex-col items-center justify-between">
      <div className="bg-[#0A080D] w-[100%] flex justify-center py-10 px-2 md:py-0 md:px-0" style={{ overflow: 'hidden' }}>
        <div className="justify-between items-center flex flex-row" style={{ maxWidth: rem(1598), width: rem(1598) }}>
          <Group className="flex flex-col items-center md:items-start md:w-[50.4375rem] ">
            <Text className={`${inter.className}`} style={{ color: '#B6B5B7' }}>Safe  ·  Legal  ·  Transparent  ·  Leading</Text>
            <Title className={`${inter.className} text-center md:text-left`} order={1} color="#fff">HASH Miner Global Leading Arithmetic Mining</Title>
            <Title className={`${inter.className} text-center md:text-left`} order={1} color="#fff">Get a sneak peek and a<Text span c="#FBBF24" inherit> free USDT</Text></Title>
            <Group className='flex justify-center'>
              <Group position="center">
                <Button variant="outline" component="a" className={`${inter.className}`} target="_blank" rel="noopener noreferrer" href="https://hash-miner-s3.s3.ap-east-1.amazonaws.com/download/hashminer.apk" leftIcon={<AndroidSVG style={{ width: rem(24), height: rem(24), color: '#FBBF24' }} />} styles={(theme) => ({
                  root: {
                    color: '#FBBF24',
                    width: rem(220),
                    border: '1px solid #FBBF24',
                    borderRadius: rem(10),
                    height: rem(46),
                    paddingLeft: rem(46),
                    paddingRight: rem(46),
                    '&:not([data-disabled])': theme.fn.hover({
                      // backgroundColor: theme.fn.darken('#FBBF24', 0.05),
                      backgroundColor: '#fbbf2433',
                    }),
                  },
                  leftIcon: {
                    marginRight: theme.spacing.md,
                  },
                })}
                >
                  Android APK
                </Button>
              </Group>
              <Group position="center">
                <Button variant="outline" component="a" className={`${inter.className}`} target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=net.hashminer.app" leftIcon={<GooglePlaySVG style={{ width: rem(24), height: rem(24), color: '#FBBF24' }} />} styles={(theme) => ({
                  root: {
                    color: '#FBBF24',
                    width: rem(220),
                    border: '1px solid #FBBF24',
                    borderRadius: rem(10),
                    height: rem(46),
                    paddingLeft: rem(46),
                    paddingRight: rem(46),
                    '&:not([data-disabled])': theme.fn.hover({
                      // backgroundColor: theme.fn.darken('#FBBF24', 0.05),
                      backgroundColor: '#fbbf2433',
                    }),
                  },
                  leftIcon: {
                    marginRight: theme.spacing.md,
                  },
                })}
                >
                  Google Play
                </Button>
              </Group>
              <Group position="center">
                <Button variant="outline" component="a" className={`${inter.className}`} target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/hashminer/id6447461314" leftIcon={<AppStoreSVG style={{ width: rem(24), height: rem(24), color: '#FBBF24' }} />} styles={(theme) => ({
                  root: {
                    color: '#FBBF24',
                    width: rem(220),
                    border: '1px solid #FBBF24',
                    borderRadius: rem(10),
                    height: rem(46),
                    paddingLeft: rem(46),
                    paddingRight: rem(46),
                    '&:not([data-disabled])': theme.fn.hover({
                      // backgroundColor: theme.fn.darken('#FBBF24', 0.05),
                      backgroundColor: '#fbbf2433',
                    }),
                  },
                  leftIcon: {
                    marginRight: theme.spacing.md,
                  },
                })}
                >
                  App Store
                </Button>
              </Group>


            </Group>
          </Group>
          <Box style={{ width: rem(458) }} className='hidden md:block'>
            <BackgroundImage src="/home/phone.png" style={{ width: rem(387), height: rem(489), position: 'relative', marginRight: rem(71), marginTop: rem(64), display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
              <Box style={{ width: rem(68), height: rem(354), background: 'linear-gradient(180deg, #F9CA26 0%, rgba(249,202,38,0) 100%)', borderRadius: rem(20), position: 'absolute', left: rem(86), top: rem(64), opacity: 0.2 }}></Box>
              <Box style={{ width: rem(48), height: rem(354), background: 'linear-gradient(180deg, #F9CA26 0%, rgba(249,202,38,0) 100%)', borderRadius: rem(20), position: 'absolute', right: rem(23), top: rem(13), opacity: 0.2 }}></Box>
              <BackgroundImage src="/home/butterfly.png" style={{ width: rem(67), height: rem(53), position: 'absolute', top: rem(245), left: rem(-34) }}></BackgroundImage>
              <BackgroundImage src="/home/t7YK5r2NQjZn3cDvA.png" style={{ width: rem(142), height: rem(141), position: 'absolute', top: rem(-39), right: rem(-52) }}></BackgroundImage>
              <BackgroundImage src="/home/g4MnR7KbZz9p.png" style={{ width: rem(73), height: rem(58), position: 'absolute', top: rem(94), right: rem(-32) }}></BackgroundImage>
              <BackgroundImage src="/home/8dFhT6rVwX1s.png" style={{ width: rem(194), height: rem(192), position: 'absolute', top: rem(285), left: rem(-48) }}></BackgroundImage>
              <BackgroundImage src="/home/Qs3cD9nBtL2z.png" style={{ width: rem(250), height: rem(250), position: 'absolute', top: rem(299), right: rem(-71) }}></BackgroundImage>
              <Box style={{ width: rem(125), height: rem(125), borderRadius: rem(25), background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: 1 }}>
                <BackgroundImage src="/home/72x72.png" style={{ width: rem(68), height: rem(68) }}></BackgroundImage>
              </Box>
              <Box style={{ background: '#fff', width: rem(130), height: rem(32), borderRadius: rem(100), display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: rem(14) }}>
                <Text style={{ color: '#101010', fontSize: rem(14) }}>HASH Miner</Text>
              </Box>
            </BackgroundImage>
          </Box>
        </div>
      </div>

      <Container size="1600" className='md:h-[30.25rem]' py="xl" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <Box className="flex flex-col items-center relative" style={{ height: rem(52) }}>
          <Title order={1} style={{ zIndex: 1, fontSize: rem(36) }}>Early Phase</Title>
          <Box className="absolute" style={{ background: '#FBBF24', width: rem(191), height: rem(11), bottom: rem(12) }}></Box>
        </Box>

        <Text c="dimmed" style={{ maxWidth: 600, marginTop: rem(10), marginBottom: rem(50) }} ta="center">
          BEEVOLUTION TECHNOLOGY PTE. LTD. Founded in 2023
        </Text>

        <Text c="dimmed" style={{ maxWidth: 1492, color: '#A1A1AA', marginTop: rem(50) }} ta="center" mt="md">
          HASH Miner Founded in 2023, it focuses on the blockchain data center industry. It has invested and built several blockchain data centers in East Asia, the United States, Singapore and China, with a power supply scale of more than
          large substations, power plants, hydropower stations, etc, along the industry investment in data center engineering companies, server power production plants, graphics card and hard disk server assembly lines.Authorize MSB
          and related business licenses in the United States and Singapore to operate the business in each region. has invested in distributed storage public chain, mining machine value-added services, block chain signature public chain,
          computing mining pool, mining machine and electrical business platform, cloud computing power e-commerce platform, etc.
        </Text>

      </Container>
      <Box style={{ height: rem(602), background: '#0A080D', width: '100%' }} className="flex justify-center">
        <Box style={{ maxWidth: rem(1598), width: rem(1598), height: rem(602) }} className="flex flex-row items-center justify-between">
          <Box style={{ height: rem(602), }} className='hidden md:block'>
            <BackgroundImage src="/home/41xag2gz.png" style={{ width: rem(819), height: rem(646), top: rem(-44) }} className="relative"></BackgroundImage>
          </Box>
          <Box className='md:w-[32.1875rem] md:items-end items-center px-2 md:px-0' style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Title className={`${inter.className}, text-center md:text-right`} order={1} color="#fff">Explore endless possibilities with HASH Miner</Title>
            <Text className='text-center md:text-right`}' style={{ color: "#fff", marginTop: rem(20) }}>Earn income easily and effortlessly Enjoy the freedom of wealth anytime, anywhere!</Text>
            <Box style={{ marginTop: rem(100), display: 'flex', alignItems: 'center' }}>
              {/* <IconUsers color="#fff"/> */}
              <Icon name="IconUsers" />
              <Text style={{ color: '#fff', fontSize: rem(14), marginLeft: rem(10) }}>Registered Users Breakthrough</Text>
            </Box><Box style={{ marginTop: rem(10), display: 'flex', alignItems: 'center' }}>
              <Title style={{ color: '#FBBF24' }} align={"right"} className={`${inter.className}`} order={1} color="#fff">+ 0000000</Title>
              <Title style={{ marginLeft: rem(13) }} align={"right"} className={`${inter.className}`} order={1} color="#fff">User</Title>
            </Box>
          </Box>
        </Box>
      </Box>

      <FeaturesCards />

      <Container
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          height: rem(525),
          maxWidth: 'initial',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: rem(50)
        }}
      >
        <video
          autoPlay
          loop
          muted
          style={{
            position: "absolute",
            width: "100%",
            left: "50%",
            top: "50%",
            height: "100%",
            objectFit: "cover",
            transform: "translate(-50%, -50%)",
            zIndex: -1,
          }}
        >
          <source src="/home/rwasdsa.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: -1,
          }}
        ></div>

        <Title ta="center" className={`${inter.className}`} order={1} color="#fff">Supercharge your mining with HashMiner</Title>
        <Text ta="center" className={`${inter.className}`} style={{ color: '#A1A1AA', maxWidth: rem(594), marginTop: rem(20) }}>Mining machines are all set and ready to go. With a few clicks, you can start crypto mining and help safeguard the network.</Text>
        <Button variant="filled" color="yellow" style={{ backgroundColor: '#FBBF24', width: rem(200), height: rem(46), marginTop: rem(20), color: '#101010' }}>Byt Products</Button>
      </Container>

    </main>
  )
}

